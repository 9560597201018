import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { formatUrl } from 'util/format-url';
import { User } from 'typings/user';

const BASE_URL = process.env.NEXT_PUBLIC_APIGW_URI ?? '';
const APIGW_KEY = process.env.NEXT_PUBLIC_APIGW_KEY ?? '';
const PARAMS_URL = `v2/me?X-OLX-TEAM-KEY=${APIGW_KEY}`;
const accountsURL = formatUrl(BASE_URL, PARAMS_URL);

const fetcher = async (url: string) =>
  fetch(url, {
    headers: {
      accept: '*/*',
      'accept-language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7',
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: null,
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  })
    .then((r) => r.json())
    .catch(() => null);

type TypeHook = {
  user: any;
  loading: boolean;
  error?: any;
};

const buildUser = (data: User): User => ({
  ...data,
  firstName: data.nickname || data.fullName?.split(' ')[0],
});

const useLoggedUser = (): TypeHook => {
  const [user, setUser] = useState<any | null>(undefined);

  const { data, error } = useSWR(accountsURL, fetcher, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data !== undefined && (!data || error)) {
      setUser(null);
      return;
    }

    if (data) {
      setUser(buildUser(data));
    }
  }, [data]);

  return {
    user,
    loading: false,
    error,
  };
};

export default useLoggedUser;
